import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-matchday-live',
    templateUrl: './matchday-live.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetMatchdayLiveComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    matchId: string | number | null | undefined;

    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_matchday_live';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-matchday-live') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_matchday_live) {
              Opta.widgets.ref_widget_matchday_live.destroy(true);
          }
      }

      getHtml(): string {
          return `
<opta-widget widget="matchday_live"   widget_id="ref_widget_matchday_live"
competition="${this.competitionId}" season="${this.seasonId}" match="${this.matchId}" load="false" template="normal" 
live="true" orientation="horizontal" side="both" show_match_header="true" show_crests="false" 
show_pitch_crests="false" show_team_formation="true" show_score="true" show_halftime_score="false" 
show_competition_name="false" show_date="false" date_format="dddd D MMMM YYYY" narrow_date_format="DD/MM/YY" 
show_referee="true" show_venue="true" show_attendance="true" show_images="false" show_pitch_images="true"
 show_team_sheets="false" show_event_icons="true" show_player_names="true" show_player_stats="true" show_subs="none" 
competition_naming="full" team_naming="abbr" player_naming="match_name" show_live="true" show_logo="false" 
 title="${this.transHeadline}"  show_title="false" breakpoints="400, 700" sport="football"></opta-widget>

       

          `;
      }
}
