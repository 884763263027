/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/**
 *  Match detail component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    Component,
    OnInit,
    OnChanges,
    OnDestroy,
    Input,
    Output,
    SimpleChanges,
    EventEmitter,
    HostListener,
    AfterViewInit,
    Inject,
    NgZone,
    ChangeDetectorRef,
} from '@angular/core';
import { Subscription, timer, Observable, interval } from 'rxjs';
import { skipWhile, map, tap, take, takeWhile, delay } from 'rxjs/operators';
import * as _ from 'underscore';
import { toPairs, upperFirst, camelCase, fromPairs } from 'lodash-es';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SportConfigToken } from 'src/app/config/sport.config';
import WIDGET_CONFIG from '@widgets/config/widget.config';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { GetOdds } from 'src/app/store/actions/bets.action';
import { BetsState } from 'src/app/store/states/bets.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import {
    MatchData,
    Team as TeamIntefrace,
    MatchActions as MatchActionsInterface,
    MatchState,
    MatchDetailDisplayMode,
    Phrases,
    RefereeType,
    TotalStatistics,
    Probability,
    Timeline,
} from '@interfaces/match-data.interface';
import { TennisRankingInterface } from '@interfaces/tennis-rankings.interface';
import { LangService } from '@services/lang.service';
import { ClientService } from '@services/client.service';
import { BetsInterface } from '@interfaces/bets-market.interface';

import { LANG } from '@interfaces/lang.interface';

import { PageState } from '@store/states/page.state';


import { UtilsService } from '@services/utils.service';

import { propertyMetada } from '@services/decorators/propety.decorator';

import { SourceId } from '@interfaces/source.interface';

import { WindowRefService } from '@shared/window-ref';

import { PageService } from '../../services/page.service';
import { MatchService } from '../../services/match.service';
import { MetaService } from '../../services/meta.service';
import { TournamentService } from '../../services/tournament.service';
import { BaseComponent } from '../base.component';
import { environment } from '../../../environments/environment';
import { CupTreeComponent } from '../cup-tree/cup-tree.component';
import { AuthetificationDialogComponent } from '../user/authetification/authetification.component';

import { UserState } from '@/store/states/user.state';


declare const dataLayer: any;
// declare const sssp: any;

@Component({
    selector: 'app-match-detail',
    templateUrl: './match-detail.component.html',
})
export class MatchDetailComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input()
    public id: number;

    @Input()
    public tracker: boolean = false;

    @Input()
    public tabVisible: boolean = true;

    @Input()
    public stream: boolean = false;

    @Input()
    public matchState: MatchState;

    @Input()
    public fullScreen: boolean = false;

    @Input()
    public followView: boolean = false;

    @Input()
    public noAdds: boolean = false;

    @Input()
    public widgetUrl: boolean = false;

    @Input()
    public forceCache: boolean = false;

    @Input()
    public modeType: MatchDetailDisplayMode;

    @Input()
    public disableAuth: boolean = false;

    @Output()
    private onLoad: EventEmitter<MatchData> = new EventEmitter();

    @Select(PageState.darkMode) darkMode$: Observable<boolean>;

    @Select(UserState.isLoggedIn) public isLoggedIn$: Observable<boolean>;

    public loginStatus = false;

    public data?: MatchData;

    public participants: { [prop: string]: TeamIntefrace };

    public matchActions?: MatchActionsInterface[];

    public tab: string = 'info';

    private subTab: string = 'overview';

    private subTab2: string | null = null;

    private subscriber: Subscription;

    private subscriberTimer: Subscription;

    public first: boolean = true;

    public cuptree: any;

    public map: boolean = false;

    private canReset: boolean = true;

    public redirectUrl: string | null = null;

    private backupVal = false;

    public $odds: Observable<any>;

    public odds: (number | string)[] | null;

    public oddsLoaded = false;

    public shareBtnAct: boolean = false;

    private startHeight: number;
    private startOffset: number;

    public activeInning = 0;

    private pastActiveSubs: string[] = [];

    private darkMode = false;

    public playersError: boolean = false;
    public playersAction: boolean|null = null;

    public sportName: string;

    public isDisabled = false;

    public shardeOverloadUrl?: string;

    private trackerError: boolean = false;

    @HostListener('document:click', ['$event'])
    private onClick(): void {
        this.shareBtnAct = false;
    }

    public constructor(
        public page: PageService,
        private match: MatchService,
        private tournament: TournamentService,
        public user: UserService,
        private router: Router,
        private activatedRouter: ActivatedRoute,
        public dialog: MatDialog,
        private store: Store,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
        public lang: LangService,
        public client: ClientService,
        private translate: TranslateService,
        private meta: MetaService,
        protected ref: ChangeDetectorRef,
        private ngZone: NgZone,
        private windowRefService: WindowRefService,
        @Inject(SportConfigToken) public sportConfig: Record<string, any>,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.pastActiveSubs.splice(0);

        this.isLoggedIn$.subscribe((val): void => {
            this.loginStatus = val;
        });

        if (this.modeType) {
            switch (this.modeType) {
                case 'table':
                    this.activeSub('table');
                    break;
                case 'h2h':
                    this.activeSub('h2h');
                    break;
                case 'overview':
                    this.activeSub('overview');
                    break;
                case 'statistics':
                    this.activeSub('statistics');
                    break;
                case 'statistics-extended':
                    this.activeSub('statistics-extended');
                    break;
                case 'tracker':
                    this.active('tracker');
                    break;
                case 'video':
                    this.active('stream');
                    break;
                case 'lineup':
                    this.activeSub('players');
                    break;
                case 'lineup-opta':
                    this.activeSub('players');
                    break;
                case 'news':
                    this.activeSub('news');
                    break;
                default:
                    break;
            }
        }

        this.darkMode$.subscribe((mode: boolean) => {
            this.darkMode = mode;
        });
    }

    public ngAfterViewInit(): void {
        if (this.deviceService.isMobile()) {
            interval(1500)
                .pipe(
                    takeWhile(() => $('.app-match-detail-content').length > 0),
                    take(1),
                )
                .subscribe(() => {
                    const offset = $('.app-match-detail-content').offset();
                    this.startOffset = +offset!.top + +$('.app-match-detail-content').height()!;
                    this.startHeight = $('.app-match-detail-content').height() as number;
                });
        }

        // sssp.getAds([
        //     {
        //         zoneId: 342232,
        //         id: 'ssp-zone-342232',
        //         width: 300,
        //         height: 300,
        //     },
        // ]);
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriberTimer instanceof Subscription) {
            this.subscriberTimer.unsubscribe();
        }

        this.backupVal = false;
    }

    public get backupEndpoint(): boolean {
        return !this.forceCache && (this.backupVal);
    }

    public ngOnChanges(change: SimpleChanges): void {
        this.isDisabled = false;

        if (!('id' in change) && 'stream' in change) {
            if (this.stream) {
                this.tab = 'stream';
            }

            return;
        }

        if (change.id?.currentValue !== change.id?.previousValue) {
            if (change.id?.currentValue && change.id?.previousValue) {
                this.tab = 'info';

                this.activeSub('overview');
            }


            this.trackerError = false;

            this.backupVal = false;
            this.isDisabled = true;
            this.oddsLoaded = false;
        }

        if (this.activatedRouter.snapshot.paramMap.has('sport')) {
            [, this.sportName] = UtilsService.parseSportUrl(this.activatedRouter.snapshot.paramMap.get('sport')!);
        }
        if (this.activatedRouter.snapshot.paramMap.has('match_state') && !this.matchState) {
            this.matchState = this.activatedRouter.snapshot.paramMap.get('match_state') as MatchState;
        }

        if (!this.isSoccerSection && !this.isBasketball) {
            this.backupVal = true;
        }

        const keysNum = Object.keys(change);

        // eslint-disable-next-line no-empty
        if (keysNum.length === 1 && keysNum[0] === 'tabVisible') {
        } else {
            this.canReset = true;

            if (this.deviceService.isMobile()) {
                this.reset();
            } else {
                UtilsService.requestTimeout(() => {
                    if (this.canReset) {
                        this.reset();
                    }
                }, this.sportConfig.matchDetailResetTimeout);
            }
            this.subTab = 'overview';
        }

        this.pastActiveSubs.splice(0);

        this.redirectUrl = this.router.url;
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriberTimer instanceof Subscription) {
            this.subscriberTimer.unsubscribe();
        }
        this.tab = 'info';
        if (this.tracker) {
            this.tab = 'tracker';
        }
        if (this.stream) {
            this.tab = 'stream';
        }

        if (change.id?.currentValue || change.tabVisible?.currentValue !== change.tabVisible?.previousValue) {
            this.first = true;
            this.map = false;

            this.loader();
        }

        if (this.id) {
            $('body').addClass('app-match-detail--open');
            this.odds = null;
            this.$odds = this.store.select(BetsState.bet).pipe(map((filterFn): any => filterFn(this.id)));

            this.store.dispatch(new GetOdds([this.id], true))
                .pipe(delay(200)).subscribe(() => { this.oddsLoaded = true; });
        } else {
            $('body').removeClass('app-match-detail--open');
        }
    }

    private loader(): void {
        if (this.subscriberTimer instanceof Subscription) {
            this.subscriberTimer.unsubscribe();
        }
        this.load()
            .pipe(
                tap(() => {
                    if (this.stream) {
                        if (dataLayer) {
                            dataLayer.push({
                                event: 'stream',
                                streamValue: `${this.data?.id} | ${this.participants.home_team!.name}
                                        - ${this.participants.away_team!.name}`,
                            });
                        }
                    }
                }),
            )
            .subscribe(() => {
                const realoadTime =
                    this.isBefore || this.isAfter ? environment.matchReloadTimeNotLive : environment.matchReloadTime;
                this.subscriberTimer = timer(environment.matchReloadTime, realoadTime).subscribe((): void => {
                    this.load().subscribe(() => {
                        if (!this.isBefore && !this.isAfter && realoadTime === environment.matchReloadTimeNotLive) {
                            this.loader();
                        }
                        if ((this.isBefore || this.isAfter) && realoadTime === environment.matchReloadTime) {
                            this.loader();
                        }
                    });
                });
            });
    }

    get hasBettingStats(): boolean {
        const ret =
            this.client.use('betsStats') &&
            this.data?.tracker &&
            !this.isAfter &&
            toPairs(WIDGET_CONFIG).some(
                ([, val]) => val.showInPage && val.availSports.includes(this.data!.sport_code_name),
            );

        return !!ret;
    }

    /** Cricket */

    public setInning(inning: number): void {
        this.activeInning = inning;
    }

    get inning(): Record<string, any> {
        return this.data?.innings![this.activeInning] ?? {};
    }

    get hasInnings(): boolean {
        return !!(this.data?.innings && this.data.innings.length > 0);
    }

    get hasWicket(): boolean {
        return !!(this.data?.live_timelines && this.wickets.length > 0);
    }

    get wickets(): Timeline[] {
        return this.data?.live_timelines?.filter(t => t.type === 'wicket' && t.inning === this.activeInning + 1) ?? [];
    }

    get firstInningTeam(): string {
        if (!this.hasInnings) {
            return '-';
        }

        const teamId = this.data?.innings![0]!.batting_team;

        const team = this.data?.innings![0]!.teams.find((t: Record<string, any>) => t.id === teamId);

        if (!team) {
            return '-';
        }

        return team.name;
    }

    get secondInningTeam(): string | false {
        if (!this.hasInnings || this.data!.innings!.length < 2) {
            return false;
        }

        const teamId = this.data?.innings![1]!.batting_team;

        const team = this.data?.innings![1]!.teams.find((t: Record<string, any>) => t.id === teamId);

        if (!team) {
            return false;
        }

        return team.name;
    }

    get thirdInningTeam(): string | false {
        if (!this.hasInnings || !this.data?.innings?.[2]?.batting_team) {
            return false;
        }

        const teamId = this.data?.innings![2]!.batting_team;

        const team = this.data?.innings![2]!.teams.find((t: Record<string, any>) => t.id === teamId);

        if (!team) {
            return false;
        }

        return team.name;
    }

    get fourthInningTeam(): string | false {
        if (!this.hasInnings || !this.data?.innings?.[3]?.batting_team) {
            return false;
        }

        const teamId = this.data?.innings![3]!.batting_team;

        const team = this.data?.innings![3]!.teams.find((t: Record<string, any>) => t.id === teamId);

        if (!team) {
            return false;
        }

        return team.name;
    }

    /**
     * Open sign in dialog
     */
    public openSignIn(): void {
        // this.user.openModal();
        this.openModal();
    }

    get weatherIcon(): Record<string, boolean> {
        if (this.data?.sport_event_conditions?.weather_info) {
            const sky = this.data?.sport_event_conditions?.weather_info.sky_conditions;
            const rain = this.data?.sport_event_conditions?.weather_info.rain_conditions;

            if (sky === 'clear' && rain === 'no_rain') {
                return { 'wi-day-sunny': true };
            }

            if (sky === 'clear' && rain === 'expected_soon') {
                return { 'wi-day-hail': true };
            }

            if (sky === 'clear' && rain === 'showers') {
                return { 'wi-day-sleet': true };
            }

            if (sky === 'clear' && (rain === 'rain' || rain === 'heavy_rain')) {
                return { 'wi-day-rain': true };
            }

            if ((sky === 'partly_cloudy' || sky === 'cloudy') && rain === 'no_rain') {
                return { 'wi-day-cloudy': true };
            }

            if ((sky === 'partly_cloudy' || sky === 'cloudy') && (rain === 'expected_soon' || rain === 'showers')) {
                return { 'wi-day-sleet': true };
            }

            if ((sky === 'partly_cloudy' || sky === 'cloudy') && (rain === 'heavy_rain' || rain === 'rain')) {
                return { 'wi-rain': true };
            }

            if (sky === 'overcast' && rain === 'no_rain') {
                return { 'wi-cloudy': true };
            }

            if (sky === 'overcast' && (rain === 'expected_soon' || rain === 'showers')) {
                return { 'wi-showers': true };
            }

            if (sky === 'overcast' && (rain === 'heavy_rain' || rain === 'rain')) {
                return { 'wi-rain': true };
            }
        }

        return { };
    }

    public ontrackerError(e: boolean): void {
        if ((this.data?.live_timelines && this.data?.live_timelines?.length > 0) ||
            (!Array.isArray(this.data?.participantStatistics) || this.seasonStats)) {
            this.trackerError = e;
            this.ref.markForCheck();
        }
    }

    get hasOptaStats(): boolean {
        return this.client.use('opta') && !this.isBefore && this.data?.opta_match !== null &&
        this.data?.opta_competition !== null && this.data?.opta_season !== null;
    }

    get hasInternalFormation(): boolean {
        return !!(this.participants.home_team?.formation !== null && this.participants.away_team?.formation !== null);
    }

    get modeLineupOpta(): boolean {
        return this.modeType === 'lineup-opta';
    }

    get hasTrackerError(): boolean {
        return this.trackerError;
    }

    get hasNoStatistics(): boolean {
        return this.hasTrackerError && Array.isArray(this.data?.participantStatistics) &&
        this.data?.participantStatistics.length === 0;
    }

    get isTrackerSet(): boolean {
        return !!this.data?.tracker;
    }

    get hasPlayers(): boolean {
        return !!((this.data?.players && this.data.players.length > 0) ||
            (this.data?.participants[0]?.lineup_string && this.data?.participants[1]?.lineup_string));
    }


    get seasonStats(): TotalStatistics | null {
        if (this.data?.participants[0]?.seasonStats && this.data?.participants[1]?.seasonStats) {
            let statsNum = 0;
            statsNum = Object.keys(this.data?.participants[0].seasonStats).length +
                Object.keys(this.data?.participants[1]?.seasonStats).length;

            if (this.isBefore && statsNum > 4) {
                let home: any;
                let away: any;
                if (this.data?.participants[0]?.seasonStats) {
                    home = fromPairs(toPairs(this.data?.participants[0].seasonStats)
                        .map((pair: [string, string | number]) => {
                            const newKey = upperFirst(camelCase(pair[0]));
                            return [newKey, pair[1]];
                        }));
                }
                if (this.data?.participants[1]?.seasonStats) {
                    away = fromPairs(toPairs(this.data?.participants[1].seasonStats)
                        .map((pair: [string, string | number]) => {
                            const newKey = upperFirst(camelCase(pair[0]));
                            return [newKey, pair[1]];
                        }));
                }

                return { home, away };
            }
        }

        return null;
    }

    public openModal(type: string = 'signin', redirectUrl: string | null = null): void {
        redirectUrl = redirectUrl !== null ? redirectUrl : this.router.url;
        const dialogRef = this.dialog.open(AuthetificationDialogComponent, {
            width: '50%',
            data: { type, redirectUrl },
        });

        dialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }

    /**
     * Check if home team is winner
     * @return {boolean}
     */
    public isWinnerHome(): boolean {
        return (
            (this.data?.match_state === MatchState.AFTER || this.data?.match_state === MatchState.CANCELED) &&
            (
                (this.data?.winner === 1 && (this.data?.aggregate_winner === 1 ||
                    this.data?.aggregate_winner === null || this.data?.aggregate_winner === 0)) ||
            (this.data?.winner === 2 && this.data?.aggregate_winner === 1)
            )
        );
    }

    /**
     * Check if away team is winner
     * @return {boolean}
     */
    public isWinnerAway(): boolean {
        return (
            (this.data?.match_state === 'after' || this.data?.match_state === 'cancelled') &&
            (
                (this.data?.winner === 2 && (this.data?.aggregate_winner === 2 ||
                    this.data?.aggregate_winner === null || this.data?.aggregate_winner === 0)) ||
            (this.data?.winner === 1 && this.data?.aggregate_winner === 2)
            )
        );
    }

    get isCancelled(): boolean {
        return this.data?.match_state === MatchState.CANCELED;
    }

    /**
     * Check if can be display tracker info;
     * @return {boolean}
     */
    public viewTrackerInfo(): boolean {
        return !this.storage.isset(this.sportConfig.trackerInfoKey);
    }


    public get isBefore(): boolean {
        return this.data?.match_state === MatchState.BEFORE;
    }

    public get isAfter(): boolean {
        return this.data?.match_state === MatchState.AFTER;
    }

    public get isLive(): boolean {
        return (
            this.data?.match_state === MatchState.INPLAY ||
            this.data?.match_state === MatchState.INPLAYPAUSE ||
            this.matchState === MatchState.INPLAY ||
            this.matchState === MatchState.INPLAYPAUSE
        );
    }

    public get isLive2(): boolean {
        return (
            this.data?.match_state === MatchState.INPLAY ||
            this.data?.match_state === MatchState.INPLAYPAUSE
        );
    }

    private getUrl(path: string): PromiseLike<{ url: string}> {
        return new Promise((resolve, reject) => {
            this.page.uniqueUrl(path).subscribe((url) => {
                resolve(url);
            }, reject);
        });
    }

    /**
     * Click on shar ebutton icon
     */
    public async shbClick(event: MouseEvent): Promise<void> {
        // eslint-disable-next-line max-len
        const path = `/page/sport/match/${`${this.data?.sport_code_name}-${this.data?.sport_id}`}/${this.id}?lang=${this.lang.getLangSnapshot()}`;
        const { url } = await this.getUrl(path);

        this.shardeOverloadUrl = url;
        this.shareBtnAct = !this.shareBtnAct;

        event.preventDefault();
        event.stopPropagation();
    }


    public async shareDetail(): Promise<void> {
        // eslint-disable-next-line max-len
        const path = `/page/sport/match/${`${this.data?.sport_code_name}-${this.data?.sport_id}`}/${this.id}?lang=${this.lang.getLangSnapshot()}`;

        const { url } = await this.getUrl(path);

        const participants: string[] = [];

        this.data?.participants.forEach(p => participants.push(p.name_short ?? p.name));

        const ob = {
            title: this.translate.instant('web.title_match'),
            text: `${this.client.getVName()} | ${participants.join('-')}`,
            // eslint-disable-next-line max-len
            url: url ?? `${window.location.origin}${path}`,
        };
        await this.page.shareText(ob);
    }

    public get isTennisBasketballAndNotBefore(): boolean {
        return (this.isBasketball || this.isTennis) && !this.isBefore;
    }

    public get isBasketballAndNotBefore(): boolean {
        return this.isBasketball && !this.isBefore;
    }

    public get hasScoreActions(): boolean {
        return (
            !this.isBefore &&
            typeof this.data !== 'undefined' &&
            typeof this.data.match_actions_score !== 'undefined' &&
            Array.isArray(this.data.match_actions_score) &&
            this.data.match_actions_score.length > 0 &&
            typeof this.data.match_actions_score[0] !== 'undefined' &&
            'actions' in this.data.match_actions_score[0]
        );
    }

    public get hasCommentary(): boolean {
        return !this.isBefore && ((this.hasTracker &&
        (this.isSoccerSection || this.isHockeySection || this.isBasketball || this.isTennis)) ||
        !!(this.data?.match_commentaries && this.data?.match_commentaries.length > 0));
    }

    public get hasInternalCommentary(): boolean {
        return !!(this.data?.match_commentaries) && this.data?.match_commentaries.length > 0;
    }

    public get hasMatchActions(): boolean {
        return (
            !this.isBefore &&
            ((this.hasTracker && !this.isTennis && !this.isHockey(this.data!.sport_code_name)) ||
                this.data!.match_actions!.length > 0 ||
                (typeof this.data?.live_timelines !== 'undefined' && this.data?.live_timelines!.length > 0))
        );
    }

    get scoreSeries(): false | number[] {
        if (
            !this.data!.score?.series_score ||
            this.data!.score?.series_score.length === 0 ||
            !this.sportConfig.score.seriesScore.includes(this.data?.sport_code_name)
        ) {
            return false;
        }

        return this.data!.score?.series_score;
    }

    get hasH2H(): boolean {
        return this.sportConfig.isComparitionForm(this.sportName);
    }

    get scoreSeriesText(): string {
        return `${this.translate.instant('web.score_serie')}
        <strong>${this.data!.score?.series_score!.join(':')}</strong>`;
    }

    public get hasMatchEvents(): boolean {
        return this.lang.getLangSnapshot() === LANG.EN && this.data!.phrases.length > 0;
    }

    public get mapPhrases(): Phrases[] {
        return this.data!.phrases.map((p: Phrases) => {
            if (p.time === '-1') {
                p.time = null;
            }
            return p;
        });
    }

    public get showBets(): boolean {
        if (this.client.has('bet', 'isOddsOnlyBeforeAndAfter')) {
            return this.isBefore || this.isAfter;
        }

        return !!(this.isBefore || this.client.has('bet', 'isOddsLiveAndAfter'));
    }

    public get referees(): boolean {
        if (!this.data?.metainfo?.referees || this.data?.metainfo.referees.length === 0) {
            return false;
        }

        return (
            !!this.basicReferee ||
            !!this.mainReferee ||
            !!this.firstReferee ||
            !!this.secondReferee ||
            !!this.videoReferee ||
            !!this.fourthReferee
        );
    }

    public get basicReferee(): false | string {
        const main: Record<string, any>[] | undefined =
        this.data?.metainfo?.referees?.filter(r => r.type === RefereeType.BASIC);

        if (!main) {
            return false;
        }

        if (Array.isArray(main)) {
            let ret = '';
            main.forEach((ref) => {
                ret += `${ref.name} (${ref.iso ?? '-'}) <br>`;
            });

            return ret;
        }

        const mainRet:Record<string, any> = main;

        return `${mainRet.name} (${mainRet.iso})`;
    }

    public get mainReferee(): false | string {
        const main = this.data?.metainfo?.referees?.find(r => r.type === RefereeType.MAIN);

        if (!main) {
            return false;
        }

        return `${main.name} ${main.iso ? `(${main.iso})` : ''}`;
    }

    public get firstReferee(): false | string {
        const main = this.data?.metainfo?.referees?.find(r => r.type === RefereeType.FIRST_ASSISTANT);

        if (!main) {
            return false;
        }

        return `${main.name} (${main.iso})`;
    }

    public get secondReferee(): false | string {
        const main = this.data?.metainfo?.referees?.find(r => r.type === RefereeType.SECOND_ASSISTANT);

        if (!main) {
            return false;
        }

        return `${main.name} (${main.iso})`;
    }

    public get videoReferee(): false | string {
        const main = this.data?.metainfo?.referees?.find(r => r.type === RefereeType.VIDEO_ASSISTANT);

        if (!main) {
            return false;
        }

        return `${main.name} (${main.iso})`;
    }

    public get fourthReferee(): false | string {
        const main = this.data?.metainfo?.referees?.find(r => r.type === RefereeType.FOURTH_OFFICIAL);

        if (!main) {
            return false;
        }

        return `${main.name} (${main.iso})`;
    }

    public onOddsLoad(data: BetsInterface): void {
        this.odds = data.odd_types.slice(0, 3).map(v => v.rate);
    }

    /**
     * Count of periods for tennis
     */
    public get periodsCount(): number {
        return this.data?.score.periods_count ?? (this.isTennis ? 3 : 2);
    }

    get isOddsSet(): boolean {
        return this.odds?.length === 3;
    }

    get color(): string {
        return this.client.has('colors', 'icons') || (this.darkMode ? 'white' : 'black');
    }

    public phraseImg(typeId?: number): string {
        return this.sportConfig.matchActions.icons[`type${typeId}`] ?? 'empty';
    }

    /**
     * Set tracker info as view
     * @return {void}
     */
    public setsTrackerInfo(): void {
        this.storage.set(this.sportConfig.trackerInfoKey, 1);
    }

    public get hasStream(): string | number | undefined {
        return (
            this.data?.twitch_video ||
            this.data?.twitch_channel ||
            this.data?.youtube_video ||
            this.data?.nhl_video ||
            this.data?.tvcom_video ||
            this.data?.isport_video ||
            this.data?.vimeo_video
        );
    }

    get isLiveStrem(): boolean {
        return !!(this.data?.tvcom_video);
    }


    /**
     * Get participant ranking
     */
    public participantRanking(participantId: number | undefined): string {
        if (this.data?.tennis_rankings) {
            const ranking = this.data?.tennis_rankings.find(
                (t: TennisRankingInterface): boolean => t.participantId === participantId,
            );
            if (ranking) {
                return `${ranking.category.toUpperCase()} ${ranking.ranking}`;
            }
        }
        return 'NDF';
    }

    /**
     * Reset load
     * @return {void}
     */
    protected reset(updateCursor: boolean = false): void {
        super.reset(updateCursor);
        this.subTab2 = null;
    }

    /**
     * Active tab
     * @param {string} tab
     * @return {void}
     */
    public active(tab: string): void {
        this.tab = tab;
        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'click_tab',
            tab_name: tab,
            tab_sport: this.sportName,
            tab_league: this.data?.tournament_name,
        });
    }

    public get showScoreForSport(): boolean {
        return !this.sportConfig.score.ommitScoreMatch.includes(this.data?.sport_code_name);
    }

    public get probabilityWidget(): boolean {
        const isSportAvail = WIDGET_CONFIG.form.availSports.includes(this.data!.sport_code_name);

        return (
            !!this.data?.tracker && this.isBefore && isSportAvail && this.client.use('winProbability') && this.isOddsSet
        );
    }

    public get probabilityInfo(): boolean | Probability {
        let info = null;

        if (this.data?.probabilities && this.data?.probabilities.length > 0) {
            info = this.data?.probabilities.find(v => v.name === '2way' || v.name === '3way');
        }

        return !this.probabilityWidget && !this.isAfter && info && this.odds === null && this.oddsLoaded ? info : false;
    }

    isProbability(val: boolean | Probability): val is Probability {
        return typeof val !== 'boolean';
    }

    get probabilityValue(): Probability {
        if (this.probabilityInfo && !this.isProbability(this.probabilityInfo)) {
            throw new Error('Probability not set');
        }

        const val = this.probabilityInfo;

        if (this.isProbability(val) && (val as Probability).outcomes.length > 2) {
            const index = val.outcomes.findIndex(v => v.name === 'draw');
            if (index === 2) {
                val.outcomes.splice(1, 0, val.outcomes![index]!);
                val.outcomes.splice(index + 1, 1);
            }
        }

        return val as Probability;
    }

    public get formWidget(): boolean {
        const isSportAvail = WIDGET_CONFIG.form.availSports.includes(this.data!.sport_code_name);
        return !!this.data?.tracker && isSportAvail;
    }

    public get isNoDefault(): boolean {
        return this.data?.match_actions!.length === 0 && !this.hasTracker && !this.hasScoreActions;
    }

    /**
     * Active sub tab
     * @param {string} tab
     * @return {void}
     */
    public activeSub(tab: string): void {
        this.subTab = tab;

        this.pastActiveSubs.push(tab);
        if (this.subTab === 'cupTree') {
            this.cuptree = this.tournament.getCupTree(this.data?.sub_tournament_id, null);
        }

        // if () {
        //     this.activeSub2('match-info-2');
        // }

        if (this.subTab === 'innings_player_stats') {
            // this.activeSub2('match-info-25');
        }

        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'click_tab',
            tab_name: tab,
            tab_sport: this.sportName,
            tab_league: this.data?.tournament_name,
        });
    }

    get isTableLive(): boolean {
        return this.data?.liveTable ?? false;
    }

    /**
     * Show favorite
     * @return {boolean}
     */
    public get isFavoriteEnabled(): boolean {
        return this.data?.code_state !== 'postponed' && this.data?.match_state !== 'after';
    }

    /**
     * Was tab active
     * @param {string} tab
     * @return {boolean}
     */
    public wasActiveSub(tab: string): boolean {
        return this.pastActiveSubs.includes(tab);
    }

    public get isEsports(): boolean {
        return this.sportConfig.isEsport(this.data?.sport_code_name);
    }

    public get isTennis(): boolean {
        return this.sportConfig.isTennis(this.data?.sport_code_name);
    }

    public get isBasketball(): boolean {
        return this.sportConfig.isBasketball(this.data?.sport_code_name);
    }

    public get isHockeyS(): boolean {
        return this.sportConfig.isHockey(this.sportName);
    }

    public get isHockeySection(): boolean {
        return this.sportConfig.isHockey(this.data?.sport_code_name);
    }

    public get isHandballSection(): boolean {
        return this.sportConfig.isHandball(this.sportName);
    }

    public get isBasketballSection(): boolean {
        return this.sportConfig.isBasketball(this.sportName);
    }

    public get isTennisSection(): boolean {
        return this.sportConfig.isTennis(this.sportName);
    }

    public get isFootball(): boolean {
        return this.sportConfig.isSoccer(this.data?.sport_code_name);
    }

    public get isSoccerSection(): boolean {
        return this.sportConfig.isSoccer(this.sportName);
    }

    public get oneline(): boolean {
        return this.sportConfig.displayType.oneline.includes(this.data?.sport_code_name) === true;
    }

    get context(): [string, any][] | false {
        if (!this.data?.participantStatistics?.context) {
            return false;
        }

        return toPairs(this.data.participantStatistics.context);
    }


    public get isScoreAggregate(): boolean {
        return this.data?.score.away_aggregate !== null && this.data?.score.home_aggregate !== null;
    }

    public openCupTree(): void {
        const dialogRef = this.dialog.open(CupTreeComponent, {
            maxWidth: '94%',
            width: '94%',
            maxHeight: '94%',
            height: '94%',
            panelClass: 'app-cup-tree',
            data: {
                subId: this.data?.sub_tournament_id,
                seasonId: this.data?.season_id,
            },
        });

        dialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }

    get hasTracker(): boolean {
        if (!this.data) {
            return false;
        }
        const sport = this.data?.sport_code_name;
        return this.sportConfig.hasTracker(sport, this.data?.tournament_id) && !!this.data?.tracker;
    }

    get hasNoTrackerStatistics(): boolean {
        if (Array.isArray(this.data?.participantStatistics) && this.data?.participantStatistics.length === 0) {
            return false;
        }


        return (
            this.data?.participantStatistics &&
            this.sportConfig.statistics.participantMatchSport.includes(this.data?.sport_code_name)
        );
    }

    @propertyMetada({
        description: 'If match is nto from betaradar and has statistics',
        type: 'function',
    })
    get isNotBRAndhasNoTrackerStatistics(): boolean {
        if (Array.isArray(this.data?.participantStatistics) && this.data?.participantStatistics.length === 0) {
            return false;
        }

        return !!(
            this.data?.participantStatistics &&
            !this.isTrackerSet
        );
    }


    /**
     * Active sub tab levle 2
     * @param {string} tab
     * @return {void}
     */
    public activeSub2(tab: string | null): void {
        this.subTab2 = tab;
    }

    /**
     * Check if tab is active
     * @param {string} tab
     * @return {boolean}
     */
    public isActive(tab: string): boolean {
        return tab === this.tab;
    }

    get hasActions(): boolean {
        return (
            typeof this.data !== 'undefined' &&
            typeof this.data?.live_timelines !== 'undefined' &&
            Array.isArray(this.data?.live_timelines) &&
            this.data!.live_timelines.length > 0
        );
    }

    /**
     * Check if sub tab is active
     */
    public isActiveSub(tab: string): boolean {
        return tab === this.subTab;
    }

    public onPlayersError(): void {
        this.playersError = true;
        this.playersAction = true;
    }

    public onPlayersLoaded(): void {
        this.playersError = false;
        this.playersAction = true;
    }

    get availPlayerStats(): boolean {
        return !!this.data?.hasPlayerStats && this.sportConfig.hasPlayerStats(this.data?.sport_code_name);
    }

    get showTopPlayers(): boolean {
        return this.playersError === false && !!this.data?.hasPlayerStats &&
        this.sportConfig.hasPlayerStats(this.data?.sport_code_name);
    }

    get hasPlayerStats(): boolean {
        return (
            !!this.data?.hasPlayerStats &&
            this.sportConfig.hasPlayerStats(this.data?.sport_code_name) &&
            !this.playersError
        );
    }

    get previousScore(): {start_date: string;home_score: number;away_score: number} | false {
        if (!this.data?.score?.previous_match?.start_date || this.data?.score?.previous_match?.home_score === null ||
            this.data?.score?.previous_match?.away_score === null) {
            return false;
        }

        return this.data.score?.previous_match;
    }

    /**
     * Check if sub tab level2 is active
     * @param {string} tab
     * @return {boolean}
     */
    public isActiveSub2(tab: string | null): boolean {
        return tab === this.subTab2;
    }

    /**
     * Check if sub tab level2 is active
     * @param {string} tab
     * @return {boolean}
     */
    public recalculateActiveSub2():void {
        if (
            ((!_.isUndefined(this.data) &&
                this.data?.match_actions!.length === 0 &&
                !this.sportConfig.hasTracker(this.data?.sport_code_name)) ||
                this.data?.match_state === 'before') &&
            this.subTab2 === null
        ) {
            if (this.hasMatchActions && this.client.use('matchDetailMatchActions')) {
                this.subTab2 = 'match-info-1';
            } else if (this.probabilityWidget) {
                this.subTab2 = 'match-info-5';
            } else {
                this.subTab2 = 'match-info-2';
            }
        } else if (!_.isUndefined(this.data) && this.subTab2 === null) {
            this.subTab2 = 'match-info-2';

            if (this.hasMatchActions && this.client.use('matchDetailMatchActions')) {
                this.subTab2 = 'match-info-1';
            } else if (this.hasScoreActions) {
                this.subTab2 = 'match-info-7';
            } else if (this.isNoDefault) {
                this.subTab2 = 'match-info-2';
            }
        } else if (!this.hasMatchActions) {
            this.subTab2 = 'match-info-2';
        }

        if (this.first && this.client.has('pages', 'defaultMatchDetailSubPage')) {
            this.subTab2 = this.client.has('pages', 'defaultMatchDetailSubPage') as string;

            if (this.subTab2 === 'funfacts' && !this.data?.funfacts) {
                this.subTab2 = 'match-info-2';
            }
        }
    }


    public extendH2HVar = false;
    public extendH2H(): void {
        if (!this.extendH2HVar) {
            this.first = true;
        }
        this.extendH2HVar = true;

        this.loader();
    }

    private load(): Observable<any> {
        return new Observable((observer) => {
            try {
                if (!this.tabVisible) {
                    this.finished();
                    observer.next(1);
                    return;
                }

                const h2hLimit = !this.extendH2HVar ? this.sportConfig.h2h.baseLimit : this.sportConfig.h2h.extendLimit;

                this.match
                    .getMatch(this.id, true, this.widgetUrl, h2hLimit, this.backupEndpoint)
                    .then((match) => {
                        this.subscriber = match
                            .pipe(skipWhile((val): boolean => Object.keys(val).length === 0), map((data) => {
                                // Snooker num of red balls
                                if (data?.live_timelines?.[0] &&
                                            data?.live_timelines[0].type === 'match_info' &&
                                        data?.live_timelines[0].number_of_red_balls) {
                                    const comment = this.translate.instant('web.number_of_red_balls');
                                    data?.comments?.push(`${comment}
                                            ${data?.live_timelines[0].number_of_red_balls}`);
                                }
                                return data;
                            }))
                            .subscribe(
                                (data): void => {
                                    this.processData(data).then((data2): void => {
                                        this.data = data2;

                                        if (this.first) {
                                            this.activeSub2('match-info-1');
                                            this.recalculateActiveSub2();

                                            if (
                                                this.isTennisBasketballAndNotBefore &&
                                                this.isTrackerSet &&
                                                !this.hasActions
                                            ) {
                                                this.activeSub('statistics');
                                            } else if (this.isBasketballAndNotBefore && this.isTrackerSet) {
                                                this.activeSub('statistics');
                                            } else if (
                                                this.isBefore &&
                                                this.client.use('prematchH2HDefault') &&
                                                !this.modeType
                                            ) {
                                                this.activeSub('h2h');
                                            }
                                            // Esport media Timeline support
                                            const isEsportMedia =
                                            !!(this.data?.live_timelines && this.data?.live_timelines?.length > 0 &&
            this.data?.live_timelines[0]!.source_id === SourceId.ESPORT);

                                            this.trackerError = !!isEsportMedia;
                                        }

                                        if (this.fullScreen) {
                                            const jsonLD = {
                                                '@context': 'https://schema.org',
                                                '@type': 'Event',
                                                name: `${this.data?.participants[0]?.name} - ` +
                                                    `${this.data?.participants[1]?.name}`,
                                                startDate: this.data?.start_date,
                                                eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
                                                location: {
                                                    '@type': 'Place',
                                                    name: this.data?.metainfo?.stadium,
                                                    address: this.data?.metainfo?.place ? {
                                                        '@type': 'PostalAddress',
                                                        addressLocality: this.data?.metainfo?.place,
                                                    } : undefined,
                                                },
                                                description: `${this.data?.participants[0]?.name} vs. ` +
                                                    `${this.data?.participants[1]?.name}`,
                                            };
                                            if (this.first) {
                                                this.meta.jsonLDSetter$.next(jsonLD);
                                            }
                                        }

                                        this.onLoad.emit(this.data);
                                        if (!this.first) {
                                            this.isDisabled = false;
                                        }
                                        this.first = false;
                                        this.canReset = false;
                                        this.finished();
                                        observer.next(1);
                                    });
                                },
                                (): void => {
                                    if (this.first && this.backupEndpoint) {
                                        this.error();
                                    } else if (!this.first && this.backupEndpoint) {
                                        this.error();
                                    }
                                    this.forceCache = false;
                                    this.backupVal = true;
                                    if (this.first) {
                                        this.first = false;
                                        this.loader();
                                    }

                                    observer.error();
                                },
                            );
                    })
                    .catch(() => {
                        if (this.first) {

                            //  this.error();
                        }
                    });
            } catch (e) {
                if (this.first) {
                    this.error();
                }
                observer.next(1);
            }
        });
    }

    private processData(data: MatchData): Promise<any> {
        return new Promise((resolve): void => {
            this.participants = _.indexBy(data.participants, 'type');
            this.matchActions = data.match_actions;
            resolve(data);
        });
    }

    @HostListener('window:scroll', ['$event'])
    private scroll(e: any): void {
        if (!this.deviceService.isMobile() || this.tab === 'tracker' || this.tab === 'stream') {
            return;
        }

        const top = e.srcElement.documentElement.scrollTop;
        const trigger = this.startOffset - 50;
        const contentHeight = 65;

        if (top > trigger) {
            if (!$('.app-match-detail-content').hasClass('sticky-match-detail')) {
                $('.app-match-detail-content').addClass('sticky-match-detail');
            }
            $('#app-detail-sub').css('height', `${this.startHeight}px`).show();

            const fontPercante = 2;
            const textPercante = 1.3;

            $('.app-match-detail-content').css('height', `${contentHeight}px`);
            $('.sticky-match-detail .img-team--md').css('height', `${fontPercante}rem`);
            $('.sticky-match-detail .app-short-score-final').css('fontSize', `${textPercante}rem`);
        } else {
            $('.app-match-detail-content').removeClass('sticky-match-detail');
            $('#app-detail-sub').hide();
            $('.app-match-detail-content').css('height', '');
            $('.img-team--md').css('height', '');
            $('.app-short-score-final').css('fontSize', '');
            $('.app-match-detail-content').css('height', '');
        }
    }
}
