export enum Form {
    DRAW = 'D',
    WIN = 'W',
    OTWIN = 'OW',
    LOSE = 'L',
    OTLOSE = 'OL',
}

export interface FormResponseInterface {
    status: boolean;
    first?: boolean;
}

export interface FormErrorInterface {
    global?: string[];
    control?: { [prop: string]: string[] };
}
